/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/zepto@1.2.0/dist/zepto.min.js
 * - /npm/zepto@1.2.0/src/data.js
 * - /npm/zepto@1.2.0/src/selector.js
 * - /npm/zepto@1.2.0/src/callbacks.js
 * - /npm/zepto@1.2.0/src/deferred.js
 * - /npm/zepto@1.2.0/src/fx.js
 * - /npm/zepto@1.2.0/src/fx_methods.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
